import React from "react";
import {graphql, useStaticQuery} from "gatsby";

function InternationalVolunteerForm() {
  const data = useStaticQuery(graphql`
    query InternationalVolunteerFormQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "get-involved"}}) {
        frontmatter {
          volunteerFormDetails {
            interestAreas
            infoForVolunteersDocumentLink
          }
        }
      }
    }
  `);
  const {
    volunteerFormDetails: {interestAreas, infoForVolunteersDocumentLink},
  } = data.markdownRemark.frontmatter;

  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [policeCheck, setPoliceCheck] = React.useState(null);
  const [childrenCheck, setChildrenCheck] = React.useState(null);
  const [errors, setErrors] = React.useState(null);

  const validateDates = () => {
    const start = new Date(fromDate);
    const end = new Date(toDate);
    if (!isNaN(start) && !isNaN(end) && start > end) {
      setErrors({date: 'End date must be after start date.'});
      return false;
    }
    return true;
  };
  
  const validateInterests = () => {
    const checkboxes = document.getElementsByName("interests");
    let isChecked = false;

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        isChecked = true;
        break;
      }
    }

    if (!isChecked) {
      setErrors({ interests: "Please select at least one interest area." });
    }
    return isChecked;
  };

  const handleSubmit = (event) => {
    console.log(event);
    if (!validateDates() || !validateInterests()) {
      console.log("Prevent default submit")
      event.preventDefault();
      return false;
    }
    return true;
  }

  return (
    <>
      <div id="international-volunteer" className="bg-gray-50">
        <form
          name="international-volunteer" method="post" data-netlify="true"
          className="flex flex-col flex-1 p-5 md:px-32" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="international-volunteer"/>

          <p className="text-2xl md:text-2xl font-bold text-blue-400 mt-5 mb-10">INTERNATIONAL VOLUNTEERS/INTERNS APPLY HERE</p>

          {/* Personal Details Section */}
          <div className="flex flex-col md:flex-row">
            <input name="name" className="bg-gray-200 p-4 rounded-md mb-2 md:mr-1 flex-1" placeholder="Name" required/>
            <input name="preferredPronouns" className="bg-gray-200 p-4 rounded-md mb-2 md:ml-1 flex-1" placeholder="Preferred Pronouns"/>
          </div>
          <textarea name="address" rows="3" className="bg-gray-200 p-4 rounded-md mb-2" placeholder="Address" required/>
          <div className="flex flex-row mb-2">
            <input name="mobilePhoneCountryCode" className="bg-gray-200 p-4 rounded-md mb-2 mr-1 w-20" placeholder="+44" required/>
            <input name="mobilePhone" className="bg-gray-200 p-4 rounded-md mb-2 flex-1 ml-1" placeholder="Mobile Phone" required/>
          </div>
          <input name="email" className="bg-gray-200 p-4 rounded-md mb-2" placeholder="Email" required/>
          <input name="socialMedia" className="bg-gray-200 p-4 rounded-md mb-2" placeholder="Social Media"/>
          <p className="mt-2 mb-1">Date of Birth</p>
          <input name="dateOfBirth" type="date" className="bg-gray-200 p-4 rounded-md mb-2" required/>
          <p className="text-md mt-2 mb-1">Photograph (passport style – please don’t wear sunglasses!)</p>
          <input name="photograph" type="file" className="bg-gray-200 p-4 rounded-md mb-2" required/>

          {/* Next of Kin Section */}
          <p className="text-md mt-2 mb-1">
            Name, address and telephone number of next of kin or person to be contacted in an emergency. Please state relationship:
          </p>
          <textarea name="nextOfKinDetails" rows="3" className="bg-gray-200 p-4 rounded-md mb-2" placeholder="Enter all details here" required/>

          {/* Dates Section */}
          <p className="mt-2 mb-1">What are your preferred dates to volunteer? Enter Start and End dates below.</p>
          <div>
            <div className="flex flex-col md:flex-row">
              <input name="applyFromDate" className="flex-1 bg-gray-200 p-4 rounded-md md:mr-5 mb-2"
                     type="date" required onChange={ev => setFromDate(ev.target.value)}/>
              <input name="applyToDate" className="flex-1 bg-gray-200 p-4 rounded-md md:ml-5 mb-2"
                     type="date" required onChange={ev => setToDate(ev.target.value)}/>
            </div>
            {errors?.date && <p className="mt-5 text-red-500">{errors.date}</p>}
          </div>

          {/* <!-- Background Checks Section --> */}
          <div className="mt-4 mb-1">
            <p className="bold font-bold mb-2">Which of the following background checks do you currently hold?</p>
            <p className="italic text-gray-600 mb-2">
              Please note: we require one of the following background checks. If you don’t hold either, please don’t organise it until you have been accepted as a volunteer.
            </p>
          </div>

          <div className="md:flex md:flex">
            {/* <!-- Police Check Section --> */}
            <div className="flex-1 mb-4 flex flex-col md:mr-5">
              <p className="font-bold mb-2">Police Check</p>
              <div className="mb-2">
                <label>
                  <input type="radio" name="policeCheck" value="yes"
                  checked={policeCheck === "yes"} onChange={() => setPoliceCheck("yes")} className="mr-2" required/>Yes
                </label>
                <label className="ml-4">
                  <input type="radio" name="policeCheck" value="no"
                  checked={policeCheck === "no"} onChange={() => setPoliceCheck("no")} className="mr-2"/>No
                </label>
              </div>
              {policeCheck === "yes" && <>
                <input type="text" name="policeCheckStateCountry" placeholder="State/Country of Issue"className="bg-gray-200 p-4 rounded-md mb-2" required/>
                <p className="mt-2 mb-1">Issue Date</p>
                <input type="date" name="policeCheckIssueDate" placeholder="Issue Date" className="bg-gray-200 p-4 rounded-md mb-2" required/>
                <p className="mt-2 mb-1">Expiry Date</p>
                <input type="date" name="policeCheckExpiryDate" placeholder="Expiry Date" className="bg-gray-200 p-4 rounded-md mb-2" required/>
              </>}
            </div>

            {/* <!-- Working with Children Check Section --> */}
            <div className="flex-1 mb-4 flex flex-col md:ml-5">
              <p className="font-bold mb-2">Working with Children Check</p>
              <div className="mb-2">
                <label>
                  <input type="radio" name="childrenCheck" value="yes"
                  checked={childrenCheck === "yes"} onChange={() => setChildrenCheck("yes")} className="mr-2" required/>Yes
                </label>
                <label className="ml-4">
                  <input type="radio" name="childrenCheck" value="no"
                  checked={childrenCheck === "no"} onChange={() => setChildrenCheck("no")} className="mr-2"/>No
                </label>
              </div>
              {childrenCheck === "yes" && <>
                <input type="text" name="childrenCheckStateCountry" placeholder="State/Country of Issue" className="bg-gray-200 p-4 rounded-md mb-2" required/>
                <p className="mt-2 mb-1">Issue Date</p>
                <input type="date" name="childrenCheckIssueDate" placeholder="Issue Date" className="bg-gray-200 p-4 rounded-md mb-2" required/>
                <p className="mt-2 mb-1">Expiry Date</p>
                <input type="date" name="childrenCheckExpiryDate" placeholder="Expiry Date" className="bg-gray-200 p-4 rounded-md mb-2" required/>
              </>}
            </div>
          </div>

          {/* Reasons for applying section */}
          <div className="mb-1">
            <p className="bold font-bold mb-2">Why do you want to volunteer at Raphael?</p>
          </div>
          <textarea name="volunteerReason" rows={3} className="bg-gray-200 p-4 rounded-md mb-4" placeholder="Enter you response here" required/>

          <fieldset>
            <legend className="font-bold mb-2">What are your key interest areas?</legend>
            <div className="mb-4">
              <div className="flex flex-col">
                {interestAreas.map((interest) => (
                  <label key={interest}>
                    <input className="m-2" type="checkbox" name="interests" value={interest}/>
                    {interest}
                  </label>
                ))}
              </div>
              {errors?.interests && <p className="mt-5 text-red-500">{errors.interests}</p>}
            </div>
          </fieldset>

          <div className="mb-1">
            <p className="bold font-bold mb-2">Why values and contributions would you bring to Raphael?</p>
          </div>
          <textarea name="volunteerContributions" rows={3} className="bg-gray-200 p-4 rounded-md mb-4" placeholder="Enter you response here" required/>

          <div className="mb-1">
            <p className="bold font-bold mb-2">Are there any particular skills you would like to develop by volunteering with us?</p>
          </div>
          <textarea name="volunteerSkillDevelopment" rows={3} className="bg-gray-200 p-4 rounded-md mb-4" placeholder="Enter you response here" required/>

          <div className="mb-1">
            <p className="bold font-bold mb-2">How did you first hear about Raphael (please give names where appropriate)?</p>
          </div>
          <textarea name="howDidYouHearOfRaphael" rows={3} className="bg-gray-200 p-4 rounded-md mb-4" placeholder="Enter you response here" required/>

          {/* <!-- Accommodation and Food Section --> */}
          <div className="mt-2 mb-4 flex flex-col">
            <p className="font-bold mb-2">Accommodation and Food</p>
            <p className="italic text-gray-600 mb-2">
              Sometimes we have requests from more volunteers than we have rooms in the Guest House. If this is the case, you would be asked
              to share two to a room. If you are unhappy to share then we may need to ask you to come at a different time.
            </p>
            <p className="mt-1 mb-2">Are you happy to share a room if required?</p>
            
            <label className="inline-flex items-center mb-2">
              <input type="radio" name="roomShare" value="yes" className="form-radio" required/>
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center mb-4">
              <input type="radio" name="roomShare" value="no" className="form-radio" />
              <span className="ml-2">No</span>
            </label>

            <p className="mb-2">Volunteers are served vegetarian food in the Guest House. Do you have any other dietary requirements?</p>
            <textarea name="dietaryRequirements" rows="3" className="bg-gray-200 p-4 rounded-md mb-4" placeholder="Please specify any dietary requirements."></textarea>
          
            <p className="mb-2">
              We are committed to equal opportunities. To enable us to consider any appropriate adjustments to the volunteer environment, and better support you in your
              role, please give details below of any disabilities, health issues or support needs that you’d like us to know about.
            </p>
            <textarea name="healthIssues" rows="3" className="bg-gray-200 p-4 rounded-md mb-2" placeholder="Please specify any disabilities or health issues."></textarea>
          </div>

          {/* <!-- Referee Section --> */}
          <div className="mb-1">
            <p className="bold font-bold mb-2">Names &amp; addresses of two referees and their connection with you</p>
          </div>

          <div className="mb-4 md:flex md:flex-row">            
            {/* <!-- Referee 1 --> */}
            <div className="flex flex-col flex-1 mb-4 md:mr-5">
              <p className="font-bold mb-2">Referee 1</p>
              <input type="text" name="referee1Name" placeholder="Name" className="bg-gray-200 p-4 rounded-md mb-2" required/>
              <input type="text" name="referee1Address" placeholder="Address" className="bg-gray-200 p-4 rounded-md mb-2" required/>
              <div className="flex flex-row mb-2">
                <input name="referee1MobileCountryCode" className="bg-gray-200 p-4 rounded-md mb-2 mr-1 w-20" placeholder="+44" required/>
                <input type="text" name="referee1Mobile" placeholder="Mobile Phone" className="bg-gray-200 p-4 rounded-md mb-2 flex-1 ml-1" required/>
              </div>
              <input type="text" name="referee1Relationship" placeholder="Relationship to Referee" className="bg-gray-200 p-4 rounded-md mb-2" required/>
            </div>

            {/* <!-- Referee 2 --> */}
            <div className="flex flex-col flex flex-1 flex-col mb-4 md:ml-5">
              <p className="font-bold mb-2">Referee 2</p>
              <input type="text" name="referee2Name" placeholder="Name" className="bg-gray-200 p-4 rounded-md mb-2" required/>
              <input type="text" name="referee2Address" placeholder="Address" className="bg-gray-200 p-4 rounded-md mb-2" required/>
              <div className="flex flex-row mb-2">
                <input name="referee2MobileCountryCode" className="bg-gray-200 p-4 rounded-md mb-2 mr-1 w-20" placeholder="+44" required/>
                <input type="text" name="referee2Mobile" placeholder="Mobile Phone" className="bg-gray-200 p-4 rounded-md mb-2 flex-1 ml-1" required/>
              </div>
              <input type="text" name="referee2Relationship" placeholder="Relationship to Referee" className="bg-gray-200 p-4 rounded-md mb-2" required/>
            </div>
          </div>

          {/* <!-- Applicant Acknowledgment Section --> */}
          <div className="mb-4">
            <p className="font-bold mb-2">Terms & Conditions</p>
            <ul className="list-disc pl-5 mb-4">
              <li className="disc">I have read and understood the information contained in the <strong>
                <a href={infoForVolunteersDocumentLink}>'Information for Volunteers'</a>
                </strong> document.</li>
              <li>I understand that I will sign Raphael’s <strong>'Child Protection Policy'</strong> on arrival.</li>
            </ul>
            <label>
              <input type="checkbox" name="termsAndConditionsAcknowledgement" className="mr-2" required/>
              <span className="font-bold">I understand and agree to the above conditions.</span>
            </label>
          </div>

          {Object.keys(errors || {}).length > 0 && <p className="mt-5 text-red-500">Please fix the errors in the form before proceeding.</p>}

          <input type="submit" value="APPLY"
          className="font-bold text-white bg-blue-400 hover:bg-blue-600 w-fit px-20 py-2 rounded-full mx-auto mt-10"/>
        </form>
      </div>
    </>
  );
}

export default InternationalVolunteerForm;
