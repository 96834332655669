import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../../components/layout";
import HelpMethodsSection from "../../../components/helpMethodsSection";
import ImageCaptionSection from "../../../components/imageCaptionSection";
import InternationalVolunteerForm from "./volunteer";

const InternationalVolunteerApplicationPage = (props) => {

  const data = useStaticQuery(graphql`
    query InternationalVolunteerApplicationPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "get-involved/international-volunteer-application"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  return (
    <Layout activeLink="/get-involved" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <InternationalVolunteerForm/>

      <HelpMethodsSection/>
    </Layout>
  );
};

InternationalVolunteerApplicationPage.propTypes = {};

export default InternationalVolunteerApplicationPage;
